/* eslint-disable react/prop-types */
import React, { useState } from "react"

import { withNamespaces } from "react-i18next"

import { useForm } from "react-hook-form"
import yup from "modules/form-validation"
import uploader from "modules/services/uploader"
import { useApp } from "modules/hooks/onboarding/app"

import TextField from "components/Form/TextField"
import LoginButton from "components/Button/LoginButton"
import ProfileUploader from "components/ImageUploader/ProfileUploader"
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { SendIcon, WarningIcon } from "components/icons"
import { ProfileFormBox, ActionAreaEditProfile } from "../../style"
import { LightTooltip, SharedData, ProfileArea, RadioGroup } from "./style"

const validationSchema = yup.object().shape({
  linkedin: yup.string(),
  headline: yup.string(),
  bio: yup.string(),
})

const Form = ({ user, done, show, t }) => {
  const [file, setFile] = useState(null)
  const [hideUserData, setHideUserData] = useState(
    user.hideUserData ? "no" : "yes"
  )
  const [tooltip, setTooltip] = useState(false)
  const { setLoading } = useApp()
  const defaultValues = {
    linkedin: user.linkedin,
    headline: user.headline,
    bio: user.bio,
  }

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
  })

  const changeHideUser = (event) => {
    setHideUserData(event.target.value)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    let profileImageURL = user.profileImageURL
    if (file) {
      try {
        profileImageURL = await uploader({
          file,
          project: "auth",
          entity: "user/profile-image-url",
          id: user._id,
        })
      } catch (error) {
        console.log(error)
      }
    }
    done({
      ...data,
      profileImageURL,
      hideUserData: hideUserData === "no",
    })
  }

  return (
    <ProfileFormBox
      style={{ display: show ? "block" : "none" }}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <h1>{t("title_business_card")}</h1>
      <h2>{t("subtitle_business_card")}</h2>
      <ProfileArea>
        <ProfileUploader
          setFile={(file) => setFile(file)}
          link={user.profileImageURL}
        />
      </ProfileArea>
      <TextField
        id="linkedin"
        inputRef={register}
        label={t("input_business_card_linkedin")}
        placeholder="https://linkedin.com/in/..."
        size="medium"
        name="linkedin"
        helperText={
          <span>
            {t("input_business_card_help")}{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/help/linkedin/answer/49942/localizacao-da-url-de-perfil-publico-do-linkedin"
              style={{ color: "rgba(0, 0, 0, 0.54)" }}
            >
              {t("link_click_here")}
            </a>
          </span>
        }
        errors={errors}
        autoFocus={true}
      />

      <TextField
        id="headline"
        inputRef={register}
        label={t("input_business_card_position")}
        placeholder={t("placeholder_business_card_position")}
        size="medium"
        name="headline"
        errors={errors}
      />

      <TextField
        id="bio"
        inputRef={register}
        label={t("textarea_business_card_bio")}
        placeholder={t("placeholder_business_card_bio")}
        size="medium"
        name="bio"
        variant="outlined"
        style={{ marginTop: "12px" }}
        rows={4}
        multiline
        errors={errors}
      />

      <RadioGroup name="hide" value={hideUserData} onChange={changeHideUser}>
        <FormControlLabel
          value="yes"
          control={<Radio color="primary" />}
          label={t("check_share_profile")}
        />
        <FormControlLabel
          value="no"
          control={<Radio color="primary" />}
          label={t("check_do_not_share_profile")}
        />
      </RadioGroup>

      <LightTooltip
        placement="top-start"
        open={tooltip}
        onClose={() => setTooltip(false)}
        onOpen={() => setTooltip(false)}
        leaveTouchDelay={5000}
        title={t("link_shared_data_content")}
      >
        <SharedData onClick={() => setTooltip(true)}>
          <WarningIcon />
          {t("link_shared_data_title")}
        </SharedData>
      </LightTooltip>

      <ActionAreaEditProfile>
        <LoginButton
          type="submit"
          endIcon={<SendIcon style={{ color: "#fff" }} />}
        >
          {t("button_continue")}
        </LoginButton>
      </ActionAreaEditProfile>
    </ProfileFormBox>
  )
}

export default withNamespaces()(Form)
