/* eslint-disable react/display-name */
import React from "react"
import {
  GlobalStyle,
  GroupSingle,
  GroupForm,
  DefaultBackground,
} from "../style"

import LoginRouter from "../Router"

export default () => {
  return (
    <DefaultBackground>
      <GroupSingle>
        <GlobalStyle />
        <GroupForm>
          <img
            src="/img/startse-logo.svg"
            className="logo"
            alt="StartSe logo"
          />
          <LoginRouter />
        </GroupForm>
      </GroupSingle>
    </DefaultBackground>
  )
}
