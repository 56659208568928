import React, { useState, useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { useLocation } from "react-router-dom"

import qs from "qs"

import { withNamespaces } from "react-i18next"

import InputAdornment from "@material-ui/core/InputAdornment"

import auth from "modules/services/auth"
import enrollUser from "modules/services/lambdas/enrollUser"
import updateHubspotProps from "modules/services/lambdas/updateHubspotProps"
import { useApp } from "modules/hooks/login/app"
import { useClient } from "modules/hooks/login/client"
import { useTracking } from "modules/hooks/tracking"
import yup from "modules/form-validation"

import TextField from "components/Form/TextField"
import { LockIcon } from "components/icons"
import LinkQS from "components/Router/LinkQS"
import LoginButton from "components/Button/LoginButton"
import PreLoggedUser from "components/PreLoggedUser"
import RedirectQS from "components/Router/RedirectQS"

import Error from "./Error"

import { FormButtons, SignupForm, SignupContainer } from "./style"

const validationSchema = yup.object().shape({
  password: yup.string().required(),
})

const LocalLogin = ({ t }) => {
  const { search } = useLocation()
  const queryString = useMemo(() => {
    let _search = search ? search : ""
    return qs.parse(_search.replace("?", ""))
  }, [search])

  const { track, identify } = useTracking()

  const location = useLocation()
  const defaultValues = {
    email: location.state?.email || "",
  }
  const [error, setError] = useState(null)
  const { setLoading } = useApp()
  const { client, redirect, setViewTitle } = useClient()

  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    defaultValues,
  })

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const response = await auth.post(
        "auth/signin",
        {
          ...data,
          email: defaultValues.email,
          client_id: client.client_id,
        },
        { withCredentials: true }
      )

      if (!response || !response.data || !response.data.email) throw new Error()

      const eventSlug = queryString?.event
      if (eventSlug) {
        await enrollUser(response.data._id, eventSlug)
        await updateHubspotProps(response.data, eventSlug)
      }

      try {
        identify(response.data.email)
        track("Login", {
          redirect_to: queryString.redirect_to
            ? queryString.redirect_to
            : queryString.redirect_uri,
        })
      } catch (err) {
        console.log(err)
      }

      redirect()
    } catch (error) {
      console.log(error)

      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      )
        return setError(error.response.data.message)
      setError(`${t("text_error_message_login")}`)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setViewTitle(`${t("text_welcome_back_login")}`)
    // eslint-disable-next-line
  }, [])

  if (!location.state?.email) return <RedirectQS to="/authentication/signin" />

  return (
    <SignupContainer>
      <h1 className="login-local-title">{t("text_welcome_back_login")}</h1>

      <PreLoggedUser email={defaultValues.email} />

      <SignupForm onSubmit={handleSubmit(onSubmit)} noValidate>
        <h3 className="login-local-subtitle">
          {t("text_password_to_continue_login")}
        </h3>
        <TextField
          id="password"
          inputRef={register}
          label={t("input_main_modal_password")}
          size="medium"
          name="password"
          type="password"
          autoFocus={true}
          errors={errors}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />

        <Error text={error} />

        <FormButtons>
          <LinkQS
            to="/authentication/password/forgot"
            state={{ ...defaultValues }}
            onClick={() => {
              if (defaultValues.email) {
                identify(defaultValues.email)
                track("Forgot Password")
              }
            }}
          >
            {t("question_forgot_password")}
          </LinkQS>

          <LoginButton type="submit">{t("button_enter")}</LoginButton>
        </FormButtons>
      </SignupForm>
    </SignupContainer>
  )
}

export default withNamespaces()(LocalLogin)
