import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react"
import qs from "qs"
import auth from "modules/services/auth"
import { loginRedirect } from "modules/helpers"
import { useLocation } from "react-router-dom"
import { useUser } from "modules/hooks/onboarding/user"
import Configs from "modules/constants/views"
import cookies from "modules/cookies"

const ClientContext = createContext({})

// eslint-disable-next-line react/prop-types
export function ClientProvider({ children }) {
  const [client, setClient] = useState(null)
  const [view, setView] = useState("standard")
  const [viewTitle, setViewTitle] = useState(
    "Conecte-se usando suas redes sociais, ou usando seu e-mail:"
  )
  const [anAccount, setAnAccount] = useState(false)
  const { search } = useLocation()
  const { getUser } = useUser()

  const getQueryString = useCallback(() => {
    const currentQS = qs.parse((search || "").replace("?", "")) || {}
    return (
      "?" +
      qs.stringify({
        ...currentQS,
        ...client,
      })
    )
  }, [client, search])

  const redirect = useCallback(() => {
    const url = `${
      process.env.REACT_APP_AUTH_URI
    }oauth2/authorization${getQueryString()}`

    loginRedirect(url)
    window.location.href = url
  }, [getQueryString])

  const getClientView = useCallback(async (id) => {
    const response = await auth.get(`clients/view/${id}`)

    return (response || {}).data || null
  }, [])

  const checkForSession = useCallback(async () => {
    try {
      const response = await auth.get(`oauth2/session`, {
        withCredentials: true,
      })
      if (response && response.data.email && response.data.email) redirect()

      // eslint-disable-next-line no-empty
    } catch {}
  }, [redirect])

  const userIsLoggedIn = useCallback(async () => {
    try {
      const authCookie = cookies.get("authStartseToken")
      if (authCookie) {
        const userData = await getUser(authCookie)
        return {
          fullName: userData.firstName + " " + userData.lastName,
          image: userData.profileImageURL,
          email: userData.email,
        }
      }

      return false
    } catch (err) {
      console.log(err)
    }
  }, [getUser])

  const config = useMemo(() => Configs[view], [view])

  return (
    <ClientContext.Provider
      value={{
        client,
        setClient,
        getQueryString,
        redirect,
        getClientView,
        view,
        setView,
        checkForSession,
        config,
        setViewTitle,
        viewTitle,
        userIsLoggedIn,
        anAccount,
        setAnAccount,
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}

export function useClient() {
  const context = useContext(ClientContext)

  if (!context) {
    throw new Error("useClient must be used within an ClientProvider")
  }

  return context
}
