import React, { useState, useMemo, useEffect } from "react"
import { useForm } from "react-hook-form"

import { withNamespaces } from "react-i18next"

import yup from "modules/form-validation"
import * as patterns from "modules/form-validation/pattern"
import auth from "modules/services/auth"
import { useApp } from "modules/hooks/login/app"
import { useParams, useLocation } from "react-router-dom"

import LinkQS from "components/Router/LinkQS"
import { FormButtons, SignupForm, SignupContainer, HelperText } from "./style"
import TextField from "components/Form/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import LinearProgress from "@material-ui/core/LinearProgress"
import Success from "./Success"
import Error from "./Error"
import { useClient } from "modules/hooks/login/client"
import LoginButton from "components/Button/LoginButton"
import { LockIcon } from "components/icons"
import { useTracking } from "modules/hooks/tracking"

const CreatePassword = ({ t }) => {
  const { setLoading } = useApp()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const { id, token } = useParams()
  const { redirect, setViewTitle } = useClient()
  const { pathname } = useLocation()
  const { track, identify } = useTracking()

  const validationSchema = yup.object().shape({
    newPassword: patterns.password,
    verifyPassword: yup
      .string()
      .required()
      .oneOf(
        [yup.ref("newPassword"), null],
        `${t("text_forgot_password_same")}`
      ),
  })

  const { register, handleSubmit, errors } = useForm({ validationSchema })

  const isFirstPassword = useMemo(
    () => (pathname || "").indexOf("email-confirmation") > -1,
    [pathname]
  )

  const onNewPassword = async (data) => {
    const response = await auth.post(
      `auth/reset/${id}/${token}`,
      { ...data },
      { withCredentials: true }
    )

    if (!response || !response.data || !response.data.message)
      throw new Error(`${t("text_forgot_password_later")}`)

    track("Create a new password")
    identify(response.data.email)
  }

  const onFirstPassword = async ({ newPassword, verifyPassword }) => {
    const body = {
      password: newPassword,
      verifyPassword,
      id: id,
      token: token,
    }

    const response = await auth.post("oauth2/createPassword", body, {
      withCredentials: true,
    })

    if (response.data.email) {
      track("Create first password")
      identify(response.data.email)
    }
  }

  const onSubmit = async (data) => {
    setError(null)
    setLoading(true)
    try {
      if (isFirstPassword) await onFirstPassword(data)
      else await onNewPassword(data)

      setSuccess(true)
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      )
        return setError(error.response.data.message)

      setError(`${t("text_forgot_password_later")}`)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setViewTitle(
      isFirstPassword
        ? `${t("text_forgot_password_create")}`
        : `${t("text_forgot_password_create_new")}`
    )
    // eslint-disable-next-line
  }, [])

  if (success) {
    setTimeout(() => {
      redirect()
    }, 3000)

    return (
      <>
        <Success
          title={"Nova senha criada com sucesso!"}
          subtitle={"Seu login será feito em breve."}
        ></Success>
        <LinearProgress />
      </>
    )
  }

  return (
    <SignupContainer>
      {isFirstPassword ? (
        <h1 className="password-title">{t("text_forgot_password_create")}</h1>
      ) : (
        <h1 className="password-title">
          {t("text_forgot_password_create_new")}
        </h1>
      )}

      <SignupForm
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="password"
          inputRef={register}
          label={t("input_main_modal_password")}
          size="medium"
          name="newPassword"
          type="password"
          errors={errors}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          id="passwordAgain"
          inputRef={register}
          label={t("input_main_modal_confirm_password_sign_up")}
          size="medium"
          name="verifyPassword"
          type="password"
          errors={errors}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />

        <HelperText style={{ texAlign: "left" }}>
          {t("text_forgot_password_message")}
        </HelperText>

        <Error text={error} />
        <FormButtons>
          <LinkQS to="/authentication/signin">
            {t("text_forgot_password_login")}
          </LinkQS>
          <LoginButton type="submit">{t("button_create")}</LoginButton>
        </FormButtons>
      </SignupForm>
    </SignupContainer>
  )
}

export default withNamespaces()(CreatePassword)
