import React from "react"

import styled, { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  body {
    background-color: #FFF;
    position: relative;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;

    @media screen and (min-width: 1100px) {
      width: 100vw;
      height: 100vh;
    }
  }

  #root {
    width: 100%;
    height: 100vh;
    position: relative;
    
  }
`

export const GlobalStyleLoginIframe = createGlobalStyle`
  body {
    background-color: transparent !important;
  }

  .startse-icon {
    color: #FFFFFF !important;
  }

  .Mui-focused .startse-icon {
    color: #FFFFFF !important;
  }

  .Mui-error .startse-icon {
    color: #f44336 !important;
  }
`

export const Group = styled.div`
  height: 100%;

  @media screen and (min-width: 1100px) {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media screen and (min-width: 1600px) {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

export const GroupSingle = styled.div`
  height: 100%;

  @media screen and (min-width: 1100px) {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 600px;
    position: relative;
    border: 1px solid #acb1c0;
    border-bottom: none;
    border-top: none;
    margin: auto;
    background-color: #fff;
  }

  & img {
    max-width: 200px;
    margin: 0 auto;

    // &.logo {
    //   display: block;
    //   margin-left: 0px;
    //   padding: 10px;
    //   position: absolute;
    //   top: 0;
    //   left: 10px;
    // }
  }
`

export const GroupSingleNow = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    max-width: 200px;
    margin: 0 auto;
  }
`

export const GroupSingleIframe = styled.div`
  background-color: #000;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  h3 {
    font-weight: bold !important;
    color: #fff !important;
  }
`

export const GroupSingleLoginIframe = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  width: 100%;

  h3 {
    font-weight: bold !important;
    color: #fff !important;
  }
`

export const GroupForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: ${(props) => (props.bottom ? "0px 20px 32px" : "32px 20px")};

  @media screen and (min-width: 1000px) {
    max-width: 600px;
    margin: 0 auto;
    padding: ${(props) => (props.bottom ? "0px 20px 32px" : "0px 20px 32px")};
  }

  @media screen and (min-width: 1100px) {
    width: 440px;
    justify-content: flex-start;
    margin: ${(props) => (props.bottom ? "auto" : "0 0 0 32px")};
    max-width: initial;
  }

  & .GroupFormLogo {
    width: 200px;
    margin: 0 auto;
  }

  & h1 {
    font-size: 22px;
    color: #343434;
    font-weight: 500;
    padding: ${(props) => (props.bottom ? "0 0 10px" : "32px 0 10px")};

    // @media screen and (min-width: 1100px) {
    //   padding: 0 0 32px;
    // }
  }

  & h2 {
    font-size: 15px;
    color: #7a8fa5;
    font-weight: 300;
  }
`

export const GroupFormLoginIframe = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  & h1 {
    font-size: 22px;
    color: #343434;
    font-weight: 500;
    padding: ${(props) => (props.bottom ? "0 0 10px" : "32px 0 10px")};

    @media screen and (min-width: 1100px) {
      padding: 0 0 32px;
    }
  }

  & h2 {
    font-size: 15px;
    color: #7a8fa5;
    font-weight: 300;
  }
`

export const GroupFormNow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 0px 20px;

  @media screen and (min-width: 1000px) {
    max-width: 600px;
    margin: 0 auto;
  }

  @media screen and (min-width: 1100px) {
    width: 440px;
    justify-content: flex-start;
    margin: ${(props) => (props.bottom ? "auto" : "0 0 0 32px")};
    max-width: initial;
  }

  & .GroupFormLogo {
    width: 200px;
    margin: 0 auto;
  }

  & h1 {
    font-size: 22px;
    color: #343434;
    font-weight: 500;
    padding: ${(props) => (props.bottom ? "0 0 10px" : "32px 0 10px")};

    @media screen and (min-width: 1100px) {
      padding: 0 0 32px;
    }
  }

  & h2 {
    font-size: 15px;
    color: #7a8fa5;
    font-weight: 300;
  }
`

export const GroupFormSocial = styled.div`
  width: 100%;

  & p.divider {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #7a8fa5;
    margin: 0 0 32px;

    & span {
      width: 40px;
      margin: 0 16px;
    }

    &:before {
      display: block;
      content: "";
      background-color: #dfebf7;
      width: 100%;
      height: 1px;
    }

    &:after {
      display: block;
      content: "";
      background-color: #dfebf7;
      width: 100%;
      height: 1px;
    }
  }

  & h3 {
    font-size: 15px;
    font-weight: 300;
    color: #7a8fa5;
    padding-bottom: 16px;
  }

  & form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const GroupFormSocialLoginIframe = styled.div`
  width: 100%;

  & p.divider {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #ffffff;
    margin: 0 0 32px;

    & span {
      width: 40px;
      margin: 0 16px;
    }

    &:before {
      display: block;
      content: "";
      background-color: #dfebf7;
      width: 100%;
      height: 1px;
    }

    &:after {
      display: block;
      content: "";
      background-color: #dfebf7;
      width: 100%;
      height: 1px;
    }
  }

  & h3 {
    font-size: 15px;
    font-weight: 300;
    color: #7a8fa5;
    padding-bottom: 16px;
  }

  & form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const FormButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: "Barlow", sans-serif;
  margin: 8px 0;

  & a {
    font-size: 15px;
    color: #0080ed;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }
`

export const GroupFormSocialLogos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0 32px;

  & img {
    margin: 0 16px;
    cursor: pointer;
    border-radius: 50%;

    &.border {
      border: 1px solid #acb1c0;
    }
  }
`

export const GroupContent = styled.div`
  background: #01142b;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  padding: 32px 16px;

  @media screen and (min-width: 1100px) {
    width: 57%;
    align-items: flex-end;
    background: linear-gradient(110deg, #fff 35%, #01142b 35%, #01142b 50%);
    padding-right: 8px;
    padding: 0 32px 0 0;
  }

  & .GroupContentLogo {
    width: 200px;
    margin-bottom: 16px;

    @media screen and (min-width: 1100px) {
      width: 250px;
      margin-right: 75px;
    }

    @media screen and (min-width: 1600px) {
      margin-right: 100px;
    }
  }

  & h4 {
    font-size: 16px;
    font-weight: 300;
    color: #fff;
    line-height: 1.5;

    @media screen and (min-width: 1100px) {
      max-width: 315px;
      text-align: right;
      margin: initial;
      margin-right: 75px;
    }

    @media screen and (min-width: 1600px) {
      margin-right: 100px;
    }

    &.SubtitleLawtech {
      @media screen and (min-width: 1100px) {
        max-width: 340px;
      }
    }

    & span {
      color: #0080ed;
      font-weight: 500;
    }
  }

  & .GroupContentMac {
    width: 100%;
    margin: 16px 0;
    transition: all 0.5s ease;

    @media screen and (min-width: 1000px) {
      width: 700px;
      margin: 16px auto;
    }

    @media screen and (min-width: 1100px) {
      max-width: initial;
      margin: 16px 0;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
`

export const GroupContentBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  @media screen and (min-width: 1100px) {
    max-width: 494px;
    margin-right: 75px;
  }

  @media screen and (min-width: 1600px) {
    margin-right: 100px;
  }

  & img {
    width: 20px;
    margin: 0;

    @media screen and (min-width: 768px) {
      margin: 0 16px 0 0;
    }
  }

  & h5 {
    font-size: 16px;
    font-weight: 300;
    font-family: "Barlow", sans-serif;
    text-transform: uppercase;
    color: #fff;

    & span {
      color: #0080ed;
      font-weight: 500;
    }
  }
`
const SignupContainerUI = styled.div`
  text-align: center;
  min-width: 300px;
`
export const SignupContainer = (props) => (
  <SignupContainerUI className="signup-area" {...props} />
)

export const SignupForm = styled.form`
  margin-top: 20px;
  h3 {
    font-weight: 300;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
  }

  a.cancel {
    font-size: 15px;
    color: #0080ed;
    font-weight: 700;
    text-decoration: none;
    margin-top: 40px;

    &:hover {
      cursor: pointer;
    }
  }
`

export const HelperText = styled.h2`
  margin: 10px 0;
  text-align: left;
`

export const DefaultBackground = styled.div`
  @media screen and (min-width: 1100px) {
    background-color: #f7f8fa;
    width: 100%;
  }
`
