import React from "react"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

import OnboardingProvider from "modules/hooks/onboarding"
import LoginProvider from "modules/hooks/login"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { TrackingProvider } from "modules/hooks/tracking"
import { RegisterSocial } from "pages/Onboarding/v3"
import OnboardingV1 from "pages/Onboarding/v1"
import OnboardingV2 from "pages/Onboarding/v2"
import OnboardingV4 from "pages/Onboarding/v4"
import Subscribe from "pages/Subscribe"
import Iframe from "pages/Subscribe/Iframe"
import GlobalStyle from "styled/global"
import Login from "pages/Login/"
import Singin from "pages/Login/Sign"
import LoginIframe from "pages/Login/Iframe"
import RedirectQS from "components/Router/RedirectQS"
import PlatformOnboarding from "pages/Platform/Onboarding"
import EditProfile from "pages/Platform/EditProfile"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0080ED",
      light: "#61a7e2",
      dark: "#003a6b",
      contrastText: "#ffffff",
    },
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <TrackingProvider>
          <OnboardingProvider>
            <LoginProvider>
              <Switch>
                <Route path="/subscribe/iframe" exact component={Iframe} />
                <Route path="/subscribe" exact component={Subscribe} />
                <Route path="/frame/login" exact component={LoginIframe} />
                <Route
                  path="/frame/onboarding"
                  exact
                  component={OnboardingV1}
                />
                <Route
                  path="/frame/registersocial"
                  exact
                  component={RegisterSocial}
                />
                <Route
                  path="/frame/v2/onboarding"
                  exact
                  component={OnboardingV2}
                />
                <Route
                  path="/frame/v4/onboarding"
                  exact
                  component={OnboardingV4}
                />
                <Route
                  path="/platform/onboarding"
                  exact
                  component={PlatformOnboarding}
                />
                <Route path="/platform/profile" exact component={EditProfile} />
                <Route
                  exact
                  path="/authentication/signinV2"
                  component={Singin}
                />
                <Route path="/authentication" component={Login} />
                <RedirectQS from="/" to="/authentication/signin" />
              </Switch>
            </LoginProvider>
          </OnboardingProvider>
        </TrackingProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
