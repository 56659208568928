import React, { useState, useMemo, useEffect } from "react"

import { withNamespaces } from "react-i18next"

import { Box } from "@material-ui/core"

import { useForm } from "react-hook-form"
import { useLocation } from "react-router-dom"
import qs from "qs"
import cookies from "modules/cookies"

import Error from "../../../../Error"

import { useClient } from "modules/hooks/login/client"
import { loginRedirect, gtm } from "modules/helpers"
import auth from "modules/services/auth"
import yup from "modules/form-validation"
import { useTracking } from "modules/hooks/tracking"

import LockOpenIcon from "@material-ui/icons/LockOpen"

import TextField from "../../TextField"
import InputAdornment from "../../InputAdornment"
import EventInscription from "../EventInscription"
import LottieLoading from "../../LottieLoading"

import {
  ContainerSign,
  ContainerImages,
  Form,
  Button,
  RowText,
} from "../../../style"
import { useApp } from "modules/hooks/login/app"
import { useUser } from "modules/hooks/onboarding/user"

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
})

const SignIn = ({ handleForm, camePlatform, hideLogout, t }) => {
  const { getUser } = useUser()
  const { getQueryString, view, redirect } = useClient()
  const { register, handleSubmit, errors } = useForm({ validationSchema })
  const [passwordType, setPasswordType] = useState("password")
  const [error, setError] = useState("")
  const { viewResetPassword } = useApp()
  const { search } = useLocation()
  const { track, identify } = useTracking()
  const [loading, setLoading] = useState(false)
  const [showSubscribEvent, setShowSubscribEvent] = useState(false)

  const queryString = useMemo(() => {
    let _search = search ? search : ""
    return qs.parse(_search.replace("?", ""))
  }, [search])

  const eventSlug = useMemo(() => queryString?.event, [queryString.event])
  const token = cookies.get("authStartseToken")

  const fetchUser = async () => {
    try {
      setLoading(true)
      await getUser()
      setShowSubscribEvent(true)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (eventSlug && token) {
      fetchUser()
    }
    // eslint-disable-next-line
  }, [eventSlug, token])

  const onSubmit = async (data) => {
    setLoading(true)
    const dataPassword = {
      password: `${data.password}`,
    }
    try {
      delete data.password
      const response = await auth.post("auth/signin/email", data)

      if (!response || !response.status || response.status !== 200) {
        setError(response.message)
        throw new Error()
      }

      if (view === "prime") gtm("login", data.email)
      if (response.status === 200) {
        const responseSignIn = await auth.post(
          "auth/signin",
          {
            client_id: response.data,
            email: data.email,
            password: dataPassword.password,
          },
          { withCredentials: true }
        )

        if (
          !responseSignIn ||
          !responseSignIn.data ||
          !responseSignIn.data.email
        )
          throw new Error()

        try {
          identify(responseSignIn.data.email)
          track("Login on event page", {
            eventName: queryString.event ? queryString.event : "",
          })
        } catch (err) {
          console.log(err)
        }
        redirect()
      }
    } catch (error) {
      if (
        !error ||
        !error.response ||
        !error.response.data ||
        !error.response.data.error ||
        error.response.data.status !== 200
      ) {
        setError(error.response.data.message)
      } else setError(`${t("text_error_sign_in")}`)
    }
    setLoading(false)
  }

  const normalizeEventSlug = useMemo(() => {
    let eventName = ""
    if (eventSlug) {
      const slugSplit = eventSlug.split("-")
      slugSplit.forEach(
        (label) =>
          (eventName = `${eventName} ${label
            .charAt(0)
            .toUpperCase()}${label.slice(1)}`)
      )
    }

    return eventName
  }, [eventSlug])

  const typePassword = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password")
  }
  return (
    <>
      {!(hideLogout && loading) && (
        <ContainerSign camePlatform={camePlatform} hideLogout={hideLogout}>
          {hideLogout ? (
            <h1>{`${t(
              "text_confirm_subscription_sign_in"
            )} ${normalizeEventSlug}`}</h1>
          ) : (
            <>
              <h1>
                {camePlatform && showSubscribEvent
                  ? `${t("text_confirm_sign_up_sign_in")}`
                  : `${t("title_main_modal_sign_in")}`}
              </h1>
              {camePlatform && showSubscribEvent && (
                <h1>{normalizeEventSlug}</h1>
              )}
            </>
          )}
          {(showSubscribEvent && (
            <EventInscription eventSlug={eventSlug} hideLogout={hideLogout} />
          )) ||
            (!hideLogout && (
              <>
                <ContainerImages>
                  <p>{t("subtitle_main_modal_social_media")}</p>
                  <Box>
                    <a
                      href={`${
                        process.env.REACT_APP_AUTH_URI
                      }auth/facebook/${getQueryString()}`}
                      onClick={() => {
                        loginRedirect(
                          `${
                            process.env.REACT_APP_AUTH_URI
                          }auth/facebook/${getQueryString()}`
                        )
                      }}
                    >
                      <img src="/img/facebook-icon.svg" alt="Logo Facebook" />
                    </a>
                    <a
                      href={`${
                        process.env.REACT_APP_AUTH_URI
                      }auth/linkedin/${getQueryString()}`}
                      onClick={() => {
                        loginRedirect(
                          `${
                            process.env.REACT_APP_AUTH_URI
                          }auth/linkedin/${getQueryString()}`
                        )
                      }}
                    >
                      <img src="/img/linkedin-icon.svg" alt="Logo Linkedin" />
                    </a>
                    <a
                      href={`${
                        process.env.REACT_APP_AUTH_URI
                      }auth/google/${getQueryString()}`}
                      onClick={() => {
                        loginRedirect(
                          `${
                            process.env.REACT_APP_AUTH_URI
                          }auth/google/${getQueryString()}`
                        )
                      }}
                    >
                      <img src="/img/google-icon.svg" alt="Logo Google" />
                    </a>
                  </Box>
                  <p>{t("subtitle_main_modal_email")}</p>
                </ContainerImages>
                <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <TextField
                    label={t("input_main_modal_email")}
                    name="email"
                    placeholder={t("placeholder_main_modal_email")}
                    inputRef={register}
                    errors={errors}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <>@</>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    label={t("input_main_modal_password")}
                    name="password"
                    placeholder={t("placeholder_main_modal_password_login")}
                    type={passwordType}
                    inputRef={register}
                    errors={errors}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          onClick={() => {
                            typePassword()
                          }}
                        >
                          <LockOpenIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div
                    style={{ marginTop: "30px", fontSize: "18px !important" }}
                  >
                    <Error text={error} />
                  </div>

                  <Button>{t("button_continue")}</Button>
                  <RowText notHide>
                    <p>
                      {t("text_main_modal_forgot_password")}
                      <span onClick={viewResetPassword}>
                        {" "}
                        {t("link_main_modal_forgot_password")}
                      </span>
                    </p>
                  </RowText>
                  <RowText>
                    <p>
                      {t("text_do_not_have_account")},
                      <span onClick={handleForm}>
                        {" "}
                        {t("input_main_modal_click_here")}
                      </span>
                    </p>
                  </RowText>
                </Form>
              </>
            ))}
        </ContainerSign>
      )}
      <LottieLoading fullLoading loading={loading} />
    </>
  )
}

export default withNamespaces()(SignIn)
