import React, { useEffect, useState, useMemo } from "react"
import { useLocation } from "react-router-dom"
import qs from "qs"

import cookie from "modules/cookies"
import { closeModal, sleep } from "modules/helpers"
import { useApp } from "modules/hooks/onboarding/app"
import { useUser } from "modules/hooks/onboarding/user"

import Backdrop from "@material-ui/core/Backdrop"
import CardStep from "./Steps/Card"
import CircularProgress from "@material-ui/core/CircularProgress"
import LoadingContexting from "./Steps/Loading"
import ProfessionalStep from "./Steps/Professional"
import ProfileStep from "./Steps/Profile"
import CongratulationsStep from "./Steps/Congratulations"

import { ArrowBackIcon, CloseIcon } from "components/icons"

import {
  SubscribeArea,
  SubscribeBackground,
  HeaderEditProfile,
  CloseButton,
  Steps,
  LogoStartSe,
} from "./style"

const stepsData = ["profile", "professional", "card", "congratulations"]

const Subscribe = () => {
  const { loading, setLoading } = useApp()
  const { user, getUser, updateUser } = useUser()
  const [activeStep, setActiveStep] = useState(0)
  const [isContexting, setIsContexting] = useState(false)
  const [currentSteps, setCurrentSteps] = useState(stepsData)
  const { search } = useLocation()

  const queryString = useMemo(() => {
    let _search = search ? search : ""
    return qs.parse(_search.replace("?", ""))
  }, [search])

  const isFullModalLayout = useMemo(() => {
    if (queryString && queryString.fullModal === "true") return true
  }, [queryString])

  const close = async () => {
    if (queryString && queryString.contexting === "true") {
      setIsContexting(true)
      await sleep(3000)
    }
    if (queryString && queryString.redirect_to)
      return (window.location.href = queryString.redirect_to)
    closeModal(user)
  }

  const hideSteps = (user) => {
    let newSteps = [...currentSteps]
    const hasAllFirstFields =
      user &&
      user.company?.name &&
      user.company?.revenues &&
      user.company?.employees &&
      user.segment &&
      user.jobtitle &&
      user.department &&
      user.country &&
      user.state &&
      user.city &&
      user.phone

    if (hasAllFirstFields) newSteps = newSteps.filter((e) => e !== "mixed")

    if (queryString && queryString.hide && queryString.hide.length > 0)
      newSteps = newSteps.filter((e) => !queryString.hide.includes(e))

    setCurrentSteps(newSteps)
  }

  const noClose = useMemo(() => {
    if (queryString && queryString.noClose === "true") return true
  }, [queryString])

  useEffect(() => {
    const fetchData = async (_token) => {
      setLoading(true)

      try {
        const user = await getUser(_token)
        hideSteps(user)
      } catch (error) {
        console.log(error)
        close()
      } finally {
        setLoading(false)
      }
    }
    const token = cookie.get("authStartseToken")
    if (!token) {
      return close()
    }
    fetchData(token)
    // eslint-disable-next-line
  }, [])

  const nextStep = () => {
    if (activeStep < currentSteps.length - 1) {
      return setActiveStep(activeStep + 1)
    }
    close()
  }

  const prevStep = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1)
  }

  const saveData = async (newData) => {
    try {
      setLoading(true)
      await updateUser({
        ...user,
        ...newData,
      })
      nextStep()
    } catch (error) {
      console.log(error)
      close()
    } finally {
      setLoading(false)
    }
  }

  return (
    <SubscribeBackground transparent={isFullModalLayout}>
      {loading && (
        <Backdrop open={true} style={{ zIndex: 9 }}>
          <CircularProgress />
        </Backdrop>
      )}
      <SubscribeArea noBorder={isFullModalLayout}>
        <HeaderEditProfile>
          {activeStep !== 0 && activeStep !== 3 && !isContexting ? (
            <CloseButton onClick={prevStep} className="left-btn">
              <ArrowBackIcon />
            </CloseButton>
          ) : (
            <LogoStartSe
              src="https://startse-landings.s3.us-east-2.amazonaws.com/logos/startse-logo-s.svg"
              alt="Logo StartSe"
            />
          )}
          {!noClose && !isContexting && (
            <CloseButton onClick={close} className="right-btn">
              <CloseIcon />
            </CloseButton>
          )}
        </HeaderEditProfile>
        {user && user.email && !isContexting && (
          <>
            <ProfileStep
              show={currentSteps[activeStep] === "profile"}
              user={user}
              done={saveData}
            />
            <ProfessionalStep
              show={currentSteps[activeStep] === "professional"}
              user={user}
              done={saveData}
            />
            <CardStep
              show={currentSteps[activeStep] === "card"}
              user={user}
              done={saveData}
            />
            <CongratulationsStep
              show={currentSteps[activeStep] === "congratulations"}
              user={user}
              done={saveData}
            />
          </>
        )}
        {user && user.firstName && isContexting && (
          <LoadingContexting name={user.firstName} />
        )}
        {!isContexting &&
          currentSteps.length > 1 &&
          currentSteps[activeStep] !== "congratulations" && (
            <Steps
              steps={currentSteps.length - 1}
              variant="text"
              activeStep={activeStep}
            />
          )}
      </SubscribeArea>
    </SubscribeBackground>
  )
}

export default Subscribe
